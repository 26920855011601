import { render, staticRenderFns } from "./SigninAuthForm.vue?vue&type=template&id=4ff1a90a&scoped=true"
import script from "./SigninAuthForm.vue?vue&type=script&lang=js"
export * from "./SigninAuthForm.vue?vue&type=script&lang=js"
import style0 from "./SigninAuthForm.vue?vue&type=style&index=0&id=4ff1a90a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ff1a90a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/usr/src/app/src/components/atoms/Input.vue').default,Checkbox: require('/usr/src/app/src/components/atoms/Checkbox.vue').default,Button: require('/usr/src/app/src/components/atoms/Button.vue').default})
